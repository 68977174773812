html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.main {
  width: 100%;
  height: 100%;
  background-color: #37474f;
  display: flex;
  flex-direction: row;
}

#workarea {
  height: 100%;
  background-color: #212121;
  flex-grow: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: aliceblue;
}

#image-title {
  width: 800px;
  padding-bottom: 10px;
}

#image {
  width: 800px;
  height: 600px;
  background-color: #000;
  position: relative;
}

.image-preview {
  border: 10px solid #000;
  box-shadow: 0 0 0px 3px rgba(255, 255, 255, 0.4);
  margin: 10px auto;
  width: 80%;
  height: 200px;
  background-color: #000;
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  background-image: url(/upload-icon.png);
}
.image-preview:hover {
  background-color: #3294d4;
}
.image-preview .form-control-file {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
}

.fullsize-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#background-image,
#foreground-image {
  background-size: cover;
  background-repeat: no-repeat;
}
.preview #foreground-image {
  opacity: 0.8;
}

.preview #target-image {
  /* background-color: rgba(255, 0, 0, 0.4); */
}

#target-image {
  background-repeat: no-repeat;
}

hr {
  border-color: rgba(255, 255, 255, 0.5) !important;
}
