#side-bar {
  width: 300px;
  height: 100%;
  color: #fff;
  background-color: #363c49;
  overflow-y: scroll;
  padding-bottom: 50px;
}

#side-bar h3 {
  font-size: 20px;
}
#side-bar .logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
}

#side-bar .controls {
  margin-top: 60px;
  padding: 0 20px;
}

#side-bar .logo {
  width: 60%;
}
